const SocialMedias = () => {
  return (
    <section className="av-container">
      <div className="box-banner-seven">
        <div className="av-col-xs-24">
          <div className="social-media">
            <a
              href="https://www.facebook.com/decathlonbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/facebook-img.png"
                alt="facebook decathlon"
              />
            </a>
            <a
              href="https://www.instagram.com/decathlonbrasil/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/instagram-img.png"
                alt="instagram"
              />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=5511947312606&text=OlÃ¡, gostaria de falar com um especialista do esporte."
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/wpp-img.png"
                alt="whatsapp decathlon"
              />
            </a>
            <a
              href="https://www.youtube.com/c/DecathlonBrasilOficial"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/youtube-img.png"
                alt="youtube decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialMedias
