const YouKnow = () => {
  return (
    <section className="av-container">
      <div className="av-row box-banner-two">
        <div className="av-col-xs-24">
          <h3 className="title">Você sabia ?</h3>
          <p>
            Aqui na Decathlon, a{' '}
            <b>
              nossa missão é tornar o prazer e os benefícios do esporte
              acessíveis ao maior número de pessoas, de maneira sustentável.
            </b>
          </p>
          <p>
            Conheça agora as nossas metas globais para o nosso desenvolvimento
            sustentável, sendo que eles são divididos em 3 pilares:
          </p>
        </div>
        <div className="wrapper-content">
          <div className="av-col-xs-24 av-col-md-8">
            <a
              href="https://decathlonstore.vteximg.com.br/institucional/sustentabilidade-brasil/ambiental"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/sust-ambiental.png"
                alt="sustentabilidade ambiental"
              />
            </a>
          </div>
          <div className="av-col-xs-24 av-col-md-8">
            <a
              href="https://decathlonstore.vteximg.com.br/institucional/sustentabilidade-brasil/social"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/sust-social.png?v=637667065028400000"
                alt="sustentabilidade social"
              />
            </a>
          </div>
          <div className="av-col-xs-24 av-col-md-8">
            <a
              href="https://decathlonstore.vteximg.com.br/institucional/sustentabilidade-brasil/governanca"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/sust-governanca.png"
                alt="sustentabilidade governança"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default YouKnow
