const KnowMore = () => {
  return (
    <section className="av-container">
      <div className="box-banner-six">
        <div className="av-col-xs-24">
          <h3 className="title">QUER SABER MAIS?</h3>
          <p>
            Conheça nossas iniciativas globais de Sustentabilidade da Decathlon
            Mundo:
          </p>
        </div>
        <div className="av-col-xs-24">
          <a
            href="https://sustainability.decathlon.com/"
            target="_blank"
            rel="noreferrer"
            className="cta-el"
          >
            PROJETO INTERNACIONAL
          </a>
        </div>
      </div>
    </section>
  )
}

export default KnowMore
