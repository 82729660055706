const ProjectsBrazil = () => {
  return (
    <section className="av-container">
      <div className="box-banner-three">
        <div className="av-col-xs-24">
          <h3 className="title">PROJETOS NO BRASIL</h3>
          <p>
            Fique por dentro dos projetos que estão sendo desenvolvidos pela{' '}
            <b>Decathlon Brasil</b>:
          </p>
        </div>
        <div className="wrapper-content">
          <div className="cabides">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/sust-projeto-cabides.png"
              alt="projeto cabides"
            />
          </div>
          <div className="caixas">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/sust-projeto-caixas.png"
              alt="projeto-caixas"
            />
          </div>
          <div className="reutilizavel">
            <a href="https://www.decathlon.com.br/sacolasreutilizaveis">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/sust-sacolas-papel-reutilizavel.png"
                alt="sacolas de papel reutilizaveis"
              />
            </a>
          </div>
          <div className="ecodesign">
            <a href="https://www.decathlon.com.br/campanha/produtos-ecodesign">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/sust-ecodesign.png"
                alt="ecodesign"
              />
            </a>
          </div>
          <div className="auditoria">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/sust-auditoria.png"
              alt="auditoria"
            />
          </div>
          <div className="emissao">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/sust-emissao-co2.png"
              alt="emissao co2"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsBrazil
