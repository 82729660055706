const SectionVideo = () => {
  return (
    <section className="av-container">
      <div className="box-banner-five">
        <div className="av-col-xs-24">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UNLX-uIYdCk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </section>
  )
}

export default SectionVideo
