const BannerPrincipal = () => {
  return (
    <section className="section-top">
      <div className="box-banner-one">
        <div className="av-col-xs-24">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-sustentabilidade.png"
            className="Banner desk"
            alt=""
          />
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-sustentabilidade-mob.png"
            className="Banner mob"
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default BannerPrincipal
