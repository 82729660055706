import BackgroundFull from './BackgroundFull'
import BannerPrincipal from './BannerPrincipal'
import YouKnow from './YouKnow'
import './styles.scss'
import ProjectsBrazil from './ProjectsBrazil'
import Reports from './Reports'
import SectionVideo from './SectionVideo'
import KnowMore from './KnowMore'
import SocialMedias from './SocialMedias'

const SustentabilidadeBrasilComponent = () => {
  return (
    <>
      <main className="main-sustentabilidade">
        <div className="content-sustentabilidade">
          <div data-bg-color="#E5E5E5" />
          <div className="background-full">
            <BackgroundFull />
            <BannerPrincipal />
            <YouKnow />
            <ProjectsBrazil />
            <Reports />
            <SectionVideo />
            <KnowMore />
            <SocialMedias />
          </div>
        </div>
      </main>
    </>
  )
}

export default SustentabilidadeBrasilComponent
