const Reports = () => {
  return (
    <section className="av-container">
      <div className="box-banner-four">
        <div className="av-col-xs-24">
          <h3 className="title">NOSSOS REPORTS</h3>
          <p>
            Saiba mais sobre os principais resultados alcançados de forma global
            pela Decathlon.
          </p>
        </div>
        <div className="av-col-xs-24">
          <a
            href="https://sustainability.decathlon.com/decathlon-annual-sustainable-development-reports"
            target="_blank"
            rel="noreferrer"
            className="cta-el"
          >
            Confira
          </a>
        </div>
      </div>
    </section>
  )
}

export default Reports
